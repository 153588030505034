.infrastructure {
  display: flex;
  margin-bottom: 80px;
  .pics {
    width: calc(50% + 110px);
    position: relative;
    opacity: 1;
    transition: 1s;
    left: 0;
    top: 0;
    margin: -17px -56px -85px -61px;
    &.start {
      opacity: 0;
      left: -80px;
      top: 80px;
    }
  }
  .content {
    width: 50%;
    padding-left: 69px;
    position: relative;
    opacity: 1;
    transition: 1s;
    right: 0;
    top: 0;
    &.start {
      opacity: 0;
      right: -80px;
      top: 80px;
    }
  }
  .map {
    display: block;
    width: 100%;
    height: auto;
    //box-shadow: 0 34px 74px -20px rgba(0, 0, 0, 0.16);
  }
  .picto {
    display: block;
    position: absolute;
    left: -2.5%;
    top: 4.7%;
    width: 108%;
  }
  .button {
    position: absolute;
    bottom: 40px;
    left: 60px;
  }
}

@media all and (max-width: 1439px) {
  .infrastructure h2 {
    font-size: 42px;
  }
}

@media all and (max-width: 1168px) {
  .infrastructure h2 {
    font-size: 36px;
  }
}

@media all and (max-width: 940px) {
  .infrastructure {
    flex-wrap: wrap;
    .pics {
      order: 1;
      width: 116%;
      margin: -3% -8% -13%;
    }
    .content {
      order: 0;
      padding: 0;
      width: 100%;
      margin-bottom: 40px;
    }
    .button {
      position: static;
      margin-top: 40px;
    }
  }
}

@media all and (max-width: 700px) {
  .infrastructure h2 {
    font-size: 28px;
  }
}

@media all and (max-width: 420px) {
  .infrastructure .button {
    width: 100%;
    text-align: center;
  }
}